<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="600"
  >
    <v-card>
      <v-card-title class="caption">
        <v-row>
          <v-col cols="12">
            <h2>Role</h2>
          </v-col>
          <v-col cols="12" class="pa-0 mb-2">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" sm="3">
              <label for="rolesName">Nama Role</label>
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field
                v-mask="{regex:'^[A-Za-z]+([\\s\-\_A-Za-z]+)*$', placeholder: ''}"
                id="rolesName"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Nama Role')]"
                label="Nama Role"
                outlined
                v-model="form.rolesName"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row
          justify="end"
          class="ma-0"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <v-btn
              color="red"
              class="white--text"
              small
              block
              @click="dialog = false"
            >
              {{$_strings.common.CANCEL}}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-btn
              color="primary"
              class="white--text"
              small
              block
              :loading="isLoading"
              @click="submit"
            >
              {{$_strings.common.SAVE}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data() {
    return {
      valid: false,
      dialog: false,
      isLoading: false,
      id: null,
      form: {
        rolesName: '',
        isActive: true,
        appType: 'web_management',
      },
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.id = null;
        this.form = {
          rolesName: '',
          isActive: true,
          appType: 'web_management',
        };
      }
    },
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;
      let service = this.$_services.role.createRole;
      if (this.id) service = this.$_services.role.updateRole;
      try {
        this.isLoading = true;
        const form = { ...this.form };
        if (this.id) form.rolesId = this.id;
        await service({ form, roleId: this.id });
        this.$dialog.notify.success('Berhasil');
        this.$emit('fetchData');
        this.dialog = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
